import React from 'react'
import Logo from '../resources/img/posted-logo-full-white.png';

const SampleComponent = () => {
	return (
		<div>
			<img src={Logo} alt="" />
		</div>
	)
}

export default SampleComponent
